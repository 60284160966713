<template>
    <div class="w-full max-w-screen-md">
        <!--External Settings-->
        <div v-if="externalSettings" class="w-full">
            <div class="p-2 cursor-pointer mn3-button mn3-submit rounded-sm block flex" @click="openSettings">
                <Icon class="h-6 w-6 mr-2" id="export" />Please click here to update your settings from {{$store.state.fi.title}}'s current Digital Banking.
            </div>
        </div>
        <!--Built-In Settings-->
        <div v-else
            class="max-w-screen-md flex flex-col md:flex-row w-full">
            <!--Left Column Category Select-->
            <div :class="`w-full md:w-60 px-2 py-2 md:p-2 md:rounded-sm md:mr-2 flex md:flex-col mn3-navbar items-center md:font-bold 
                self-stretch md:self-start flex-shrink-0 flex-grow-0`">
                <div v-for="(c,key) in categories" :key="key"
                    :class="`${focusedCategory == key ? 'mn3-navbar-option_forceHover' : 'mn3-navbar-option'} w-full flex flex-col md:flex-row self-stretch items-center 
                        text-center md:text-left mr-1 md:mb-1 px-2 py-2 md:py-4 md:rounded-sm cursor-pointer duration-150 rounded-sm`"
                    @click="focusCategory(key)">
                    <Icon :id="c.icon" class="h-6 w-6 md:h-8 md:w-8 md:mr-4" />
                    <p class="text-xs md:text-base tracking-tight leading-3 md:leading-5">{{c.label}}</p>
                </div>
            </div>
            <!--Focused Category Forms-->
            <div class="flex flex-col flex-grow rounded-sm p-0 md:p-2">
                <!--Return button if needed-->
                <Button v-if="this.$route.query.return == 'transfer'" @click="this.$router.push('/transfer?restore=1')" label="Return to Transfers" icon="chevron-left" />
                <!--Header-->
                <div v-if="focusedCategory != ''" 
                    class="w-full text-2xl uppercase flex flex-col items-center justify-start">

                    <div v-for="c in categories" :key="c.id" 
                        :class="`${c.id != focusedCategory ? 'hidden' : 'w-full'}`">
                        <transition name="up" mode="out-in">
                            <div v-if="c.id == focusedCategory"
                                class="w-full flex flex-row h-16 p-2 items-center justify-start duration-150">
                                <Icon :id="c.icon" class="h-6 w-6 mr-2" />
                                <p class="text-left flex-grow">{{c.label}}</p>
                                <!--Header Buttons-->
                                <div v-if="focusedCategory == 'transfers'"
                                    @click="showNewTransfer()"
                                    class="mn3-button p-2 text-sm rounded-sm cursor-pointer border">
                                    Add Transfer
                                </div>
                                <div v-if="focusedCategory == 'alerts'"
                                    @click="alertMode = 'new';note='';error='';fv.alertType.value='';fv.alertDelivery.value='All';fv.alertAccount.value='';fv.alertAmount.value=0;fv.alertDays.value=0"
                                    class="mn3-button p-2 text-sm rounded-sm cursor-pointer border">
                                    Add Alert
                                </div>
                                <div v-if="focusedCategory == 'addresses'"
                                    @click="alertMode = 'new';note='';error='';fv.alertType.value='';fv.alertDelivery.value='All';fv.alertAccount.value='';fv.alertAmount.value=0;fv.alertDays.value=0"
                                    class="mn3-button p-2 text-sm rounded-sm cursor-pointer border">
                                    Add Address
                                </div>
                            </div>        
                        </transition>                    
                    </div>                    
                    
                </div>
                <!--Error/Note-->
                <div v-if="error != '' && (lastSub == '' || lastSub == 'username')"
                    @click="error = ''"
                    class="mn3-alert p-2 cursor-pointer rounded-sm uppercase w-full text-center font-bold">{{error}}</div>
                <div v-if="note != '' && (lastSub == '' || lastSub == 'username')"
                    @click="note = ''"
                    class="mn3-highlight p-2 cursor-pointer rounded-sm uppercase w-full text-center font-bold">{{note}}</div>
                <!--Focused Form Fields-->
                <!--Mobile Info Fields-->
                <transition name="fade" mode="out-in">
                <Form v-if="focusedCategory == 'mobile'" class="w-full flex-col flex duration-150"
                    :submitText="`${showMobileConfirm ? 'Confirm Phone' : 'Update Info'}`"
                    :showCancel="showMobileConfirm"
                    @cancel="showMobileConfirm = false;note='';error=''"
                    @submit="submitMobileInfo()">
                    <Field v-if="showMobileConfirm" class="w-full" label="Enter Confirmation Code"
                        type="text"
                        :formValue="fv.mobileConfirm" />
                    <div v-else class="w-full flex flex-row items-center justify-between">
                        <Field class="w-1/2" label="Mobile Number"
                            type="text"
                            textFormat="phone"
                            :formValue="fv.mobileNumber"
                            :options="[{}]" />
                        <Field class="w-1/2" label="Carrier"
                            type="select"
                            :formValue="fv.mobileCarrier"
                            :options="mobileCarrierOptions" />
                    </div>   
                </Form>   
                <div v-else-if="focusedCategory == 'login'"> 
                    <Form 
                        @submit="submitUpdateUsername()"
                        class="w-full flex-col flex"
                        title="Update Username"
                        submitText="Update Username">                    
                        <!--Reset Username Fields-->       
                        <Field 
                            label="New Username" 
                            type="text"
                            :formValue="fv.newUsername" />                   
                    </Form>  
                    <div v-if="error != '' && (lastSub == '' || lastSub == 'password')"
                        @click="error = ''"
                        class="mn3-alert p-2 cursor-pointer rounded-sm uppercase w-full text-center font-bold">{{error}}</div>
                    <div v-if="note != '' && (lastSub == '' || lastSub == 'password')"
                        @click="note = ''"
                        class="mn3-highlight p-2 cursor-pointer rounded-sm uppercase w-full text-center font-bold">{{note}}</div>
                    <Form 
                        @submit="submitUpdatePassword()"
                        class="w-full flex-col flex"
                        title="Update Password"
                        submitText="Update Password">                    
                        <!--Reset Password Fields-->         
                        <Field 
                            label="Current Password" 
                            type="password"
                            :formValue="fv.oldPassword" />
                        <Field 
                            label="New Password" 
                            type="password"
                            :formValue="fv.newPassword" />
                        <Field 
                            label="Confirm New Password" 
                            type="password"
                            :formValue="fv.newPassword2" />
                        <PasswordChecker ref="passwordChecker" 
                            :pass1="fv.newPassword.value" 
                            :pass2="fv.newPassword2.value" class="mx-2"
                            :maxLength="passwordReqs.maxLength"
                            :minLength="passwordReqs.minLength"
                            :minUpper="passwordReqs.minUpper"
                            :minLower="passwordReqs.minLower"
                            :minDigits="passwordReqs.minDigits"
                            :minSpecial="passwordReqs.minSpecial"
                            :specialChars="passwordReqs.specialChars" />                    
                    </Form>  
                </div>
                <div v-else-if="focusedCategory == 'transfers'" class="w-full flex-col flex">
                    <!--View existing transfers-->
                    <div v-if="transferMode == 'view'"
                        class="flex flex-col">
                        <div v-for="(t) in recurring" :key="t"
                            class="my-1 p-2 rounded-sm flex flex-row items-center 
                                justify-between mn3-content-hover cursor-default">
                            <div v-if="t.attributes.mn3"
                                class="mn3-button p-1 rounded-sm cursor-pointer mr-2 border" title="Edit Transfer"
                                @click="editTransfer = t;transferMode = 'delete'">
                                <Icon id="trash" class="md:h-8 md:w-8 h-8 w-8" />                                
                            </div>
                            <div v-else class="w-10 p-1 mr-2"></div>

                            <div class="flex flex-col flex-grow justify-center self-stretch">
                                <div class="flex flex-row justify-start leading-5">
                                    <div class="font-bold text-lg">{{t.attributes.frequency}}</div>
                                </div>
                                <div class="flex flex-row justify-start text-sm">
                                    <div class="text-left text-sm mr-4">Next Transfer: {{t.attributes.nextDate}}</div>
                                </div>
                            </div>
                            <div class="flex flex-col">                                
                                <div class="font-bold text-right">${{parseFloat(t.attributes.amount).toFixed(2)}}</div>
                                <div class="flex flex-col justify-start leading-5 text-lg items-end">
                                    <div v-if="accounts[t.attributes.fromAccountId] != undefined" class="font-bold whitespace-nowrap text-sm flex flex-row items-center">
                                        <p v-if="accounts[t.attributes.fromAccountId].external" class="text-xs mr-1">{{accounts[t.attributes.fromAccountId].ownerName}}: </p>
                                        <p>{{accounts[t.attributes.fromAccountId].displayAccountId}}</p>
                                    </div>
                                    <div v-if="accounts[t.attributes.toAccountId] != undefined" class="font-bold whitespace-nowrap text-sm flex flex-row items-center">
                                        <div div class="mr-1 text-xs">to</div>
                                        <p  v-if="accounts[t.attributes.toAccountId].external" class="text-xs mr-1">{{accounts[t.attributes.toAccountId].ownerName}}: </p>
                                        <p>{{accounts[t.attributes.toAccountId].displayAccountId}}</p>
                                    </div>
                                </div>
                            </div>
                        
                        </div>                        
                    </div>
                    <!--Add new transfer form-->
                    <Form v-else-if="transferMode == 'new' || transferMode == 'edit'  || transferMode == 'delete'" class="w-full flex flex-col"
                        :submitText="submitTransferText"
                        :showCancel="true"
                        @submit="submitTransfer()"
                        @cancel="transferMode = 'view'">
                        <!--If editing a transfer, display current values-->
                        <div v-if="transferMode == 'edit' || transferMode == 'delete'"
                            :class="`${transferMode == 'delete' ? 'mn3-alert ' : ''} mx-2 mt-2 rounded-sm flex flex-col p-4`">
                            <div v-if="transferMode == 'delete'"
                                class="font-bold">Are you sure you want to delete this recurring transfer?</div>
                            <div v-else class="font-bold">Updating Recurring Transfer</div>
                            <div class="w-full flex flex-row mt-2 items-center">
                                <div :class="`${transferMode == 'delete' ? 'mn3-content' : 'mn3-cancel cursor-pointer'} flex-shrink-0 self-start p-1 rounded-sm mr-2`" 
                                    :title="`${transferMode == 'delete' ? '' : 'Delete Transfer'}`"
                                    @click="transferMode = 'delete'">
                                    <Icon id="trash" class="md:h-8 md:w-8 h-8 w-8" />                                
                                </div>
                            <div class="flex flex-col flex-grow justify-between self-stretch">
                                <div class="flex flex-row justify-start leading-5">
                                    <div class="font-bold">{{editTransfer.attributes.frequency}}</div>
                                    <div class="mx-1 hidden">on</div>
                                    <div class="font-bold hidden">{{toFullTransferOn(editTransfer.on)}}</div>
                                </div>
                                <div class="flex flex-row justify-start text-sm">
                                    <div class="text-left text-sm mr-4">Next Transfer: {{editTransfer.attributes.nextDate}}</div>
                                </div>
                            </div>
                            <div class="flex flex-col">                                
                                <div class="font-bold text-right">${{editTransfer.attributes.amount}}</div>
                                <div class="flex flex-row justify-start leading-5 text-lg items-center">
                                    <div class="font-bold whitespace-nowrap text-sm">{{accounts[editTransfer.attributes.fromAccountId].displayAccountId}}</div>
                                    <div class="mx-1 text-xs">to</div>
                                    <div v-if="accounts[editTransfer.attributes.toAccountId] != undefined" class="font-bold whitespace-nowrap text-sm">{{accounts[editTransfer.attributes.toAccountId].displayAccountId}}</div>
                                </div>
                            </div>

                            </div>
                        </div>
                        <!--New Transfer fields-->
                        <div v-if="transferMode == 'new' || transferMode == 'edit'" class="w-full flex flex-col">
                            <div class="w-full flex flex-row items-center justify-between">
                                <Field label="Frequency" 
                                    type="select" 
                                    class="w-1/2"
                                    :formValue="fv.tranFrequency"
                                    :options="[
                                        {label:'Weekly', value:'Weekly'},
                                        {label:'BiWeekly', value:'Biweekly'},
                                        {label:'Monthly', value:'Monthly'},
                                        {label:'Quarterly', value:'Quarterly'}]" />
                                <Field label="First Transfer Date"
                                    type="date"
                                    :formValue="fv.tranOn"
                                    class="w-1/2" />
                            </div>
                            <Field label="Transfer From Account"
                                type="select"
                                :formValue="fv.tranFrom"
                                :options="optionsFrom" />
                            <Field label="Transfer To Account"
                                type="select"
                                :formValue="fv.tranTo"
                                :options="optionsTo" />
                            <Field label="Transfer Amount"
                                type="number"
                                :formValue="fv.tranAmount" />
                        </div>
                    </Form>
                </div>             
                <div v-else-if="focusedCategory == 'addresses'" class="w-full flex-col flex">
                    <div v-if="alertMode == 'view'"
                        class="flex flex-col">
                        <div v-for="(a,i) in addresses" :key="i"
                            class="my-1 p-2 rounded-sm flex flex-row items-center justify-between mn3-content-hover cursor-default">

                            <div class="mn3-button p-1 rounded-sm cursor-pointer mr-2 border flex-shrink-0" title="Edit Address"
                                @click="editAlert = a; alertMode = 'delete'">
                                <Icon id="trash" class="md:h-8 md:w-8 h-8 w-8" />                                
                            </div>
                            <div class="flex flex-col w-full">
                                <div class="font-bold text-lg leading-4">{{a.name}}</div>
                                <div class="flex flex-row justify-start">
                                    <div class="flex flex-row items-center mr-2">RTN: {{a.rtn}}</div>
                                    <div class="flex flex-row items-center">Account: {{a.account}}</div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <Form v-else-if="alertMode == 'new' || alertMode == 'edit' || alertMode == 'delete'" 
                        submitText="Add New Address" 
                        :showCancel="true" 
                        @submit="submitAddAddress();alertMode = 'view'"
                        @cancel="alertMode = 'view'">
                        <!--If editing a transfer, display current values-->
                        <div v-if="alertMode == 'edit' || alertMode == 'delete'"
                            :class="`${alertMode == 'delete' ? 'mn3-alert ' : ''} mt-2 mx-2 rounded-sm flex flex-col p-4`">
                            <div v-if="alertMode == 'delete'"
                                class="font-bold">Are you sure you want to delete this address?</div>

                            <div class="w-full flex flex-row items-center">                            
                                <div :class="`${alertMode == 'delete' ? 'mn3-content' : 'mn3-cancel cursor-pointer'} p-1 rounded-sm mr-2`" 
                                    :title="`${alertMode == 'delete' ? '' : 'Delete Alert'}`"
                                    @click="alertMode = 'delete'">
                                    <Icon  id="trash" class="md:h-6 md:w-6 h-8 w-8" />                                
                                </div>                                
                            </div>
                        </div>
                        <!--New/Edit Alert Form-->
                        <div v-if="alertMode == 'new' || alertMode == 'edit'" class="w-full flex flex-col">
                            <Field label="Account Nickname"
                                type="text"
                                :formValue="fv.alertType" />
                            <Field label="RTN"
                                type="text"
                                :formValue="fv.alertDays" />
                            <Field label="Account Number"
                                type="text"
                                :formValue="fv.alertAccount" />

                        </div>
                    </Form>
                </div>
                <div v-else-if="focusedCategory == 'alerts'" class="w-full flex-col flex">
                <!--Account Alerts-->  
                     <!--View existing alerts-->
                    <div v-if="alertMode == 'view'"
                        class="flex flex-col">
                        <div v-for="(a,i) in alerts" :key="i"
                            class="my-1 p-2 rounded-sm flex flex-row items-center justify-between mn3-content-hover cursor-default">

                            <div class="mn3-button p-1 rounded-sm cursor-pointer mr-2 border" title="Edit Alert"
                                @click="editAlert = a; alertMode = 'delete'">
                                <Icon id="trash" class="md:h-8 md:w-8 h-8 w-8" />                                
                            </div>
                            <!--Ach Transaction-->                 
                            <div v-if="a.attributes.type == 'AchTransaction'"
                                class="flex-grow text-left">
                                <span class="font-bold">ACH Transaction Completed</span>
                            </div> 
                            <!--ATM Transaction-->                 
                            <div v-else-if="a.attributes.type == 'AtmTransaction'"
                                class="flex-grow text-left">
                                <span class="font-bold">ATM Transaction Completed</span>
                            </div> 
                            <!--Balance Above-->                 
                            <div v-else-if="a.attributes.type == 'BalanceExceeds'"
                                class="flex-grow text-left">
                                <span v-if="alertAccountsDict[a.attributes.accountId] != undefined" class="font-bold">{{alertAccountsDict[a.attributes.accountId].displayAccountId}}</span> Balance is Above <span class="font-bold">${{a.attributes.value}}</span>
                            </div>   
                            <!--Balance Below-->                 
                            <div v-else-if="a.attributes.type == 'BalanceBelow'"
                                class="flex-grow text-left">
                                <span class="font-bold">{{alertAccountsDict[a.attributes.accountId].displayAccountId}}</span> Balance is Below <span class="font-bold">${{a.attributes.value}}</span>
                            </div>     
                            <!--Balance Scheduled-->                 
                            <div v-else-if="a.attributes.type == 'BalanceScheduled'"
                                class="flex-grow text-left">
                                <span class="font-bold">{{alertAccountsDict[a.attributes.accountId].displayAccountId}}</span> Balance Scheduled <span class="font-bold">{{a.attributes.frequency}}</span>
                            </div>      
                            <!--BillPay Paied-->
                            <div v-else-if="a.attributes.type == 'BillPayPaymentPaid'"
                                class="flex-grow text-left">
                                <span class="font-bold">Billpay Payment Paid</span>
                            </div>                                                                      
                            <!--Check Cleared-->
                            <div v-else-if="a.attributes.type == 'CheckNumberWithinRangeHasCleared'"
                                class="flex-grow text-left">
                                <span class="font-bold">Check Cleared</span>
                            </div>                      
                            <!--Payment Due-->                       
                            <div v-else-if="a.attributes.type == 'PaymentDueInXDays'"
                                class="flex-grow text-left">
                                <span class="font-bold">{{alertAccountsDict[a.attributes.accountId].displayAccountId}}</span> Payment Due in <span class="font-bold">{{a.attributes.value}} Days</span>
                            </div>
                            <div v-else
                                class="flex-grow text-left">
                                [{{a.attributes.type}}][{{a.attributes.accountId}}][{{a.attributes.value}}]
                            </div>
                            
                            <!--Delivery notice-->
                            <div :class="`${a.attributes.deliveryChannel != 'Email' ? 'font-bold rounded-sm  mn3-highlight' : 'line-through opacity-50'} p-1 text-xs`">SMS</div>
                            <div :class="`${a.attributes.deliveryChannel != 'SMS' ? 'font-bold rounded-sm mn3-highlight' : 'line-through opacity-50'} p-1 text-xs ml-1`">EMAIL</div>
                        </div>
                        
                    </div>
                    <Form v-else-if="alertMode == 'new' || alertMode == 'edit' || alertMode == 'delete'" 
                        :submitText="submitAlertText" 
                        :showCancel="true" 
                        @submit="submitAlert()"
                        @cancel="alertMode = 'view'">
                        <!--If editing a transfer, display current values-->
                        <div v-if="alertMode == 'edit' || alertMode == 'delete'"
                            :class="`${alertMode == 'delete' ? 'mn3-alert ' : ''} mt-2 mx-2 rounded-sm flex flex-col p-4`">
                            <div v-if="alertMode == 'delete'"
                                class="font-bold">Are you sure you want to delete this account alert?</div>

                            <div class="w-full flex flex-row items-center">                            
                                <div :class="`${alertMode == 'delete' ? 'mn3-content' : 'mn3-cancel cursor-pointer'} p-1 rounded-sm mr-2`" 
                                    :title="`${alertMode == 'delete' ? '' : 'Delete Alert'}`"
                                    @click="alertMode = 'delete'">
                                    <Icon  id="trash" class="md:h-6 md:w-6 h-8 w-8" />                                
                                </div>
                                
                                <!--Ach Transaction-->                 
                                <div v-if="editAlert.attributes.type == 'AchTransaction'"
                                    class="flex-grow text-left">
                                    <span class="font-bold">ACH Transaction Completed</span>
                                </div> 
                                <!--ATM Transaction-->                 
                                <div v-else-if="editAlert.attributes.type == 'AtmTransaction'"
                                    class="flex-grow text-left">
                                    <span class="font-bold">ATM Transaction Completed</span>
                                </div> 
                                <!--Balance Above-->                 
                                <div v-else-if="editAlert.attributes.type == 'BalanceExceeds'"
                                    class="flex-grow text-left">
                                    <span v-if="alertAccountsDict[editAlert.attributes.accountId] != undefined" class="font-bold">{{alertAccountsDict[editAlert.attributes.accountId].displayAccountId}}</span> Balance is Above <span class="font-bold">${{editAlert.attributes.value}}</span>
                                </div>   
                                <!--Balance Below-->                 
                                <div v-else-if="editAlert.attributes.type == 'BalanceBelow'"
                                    class="flex-grow text-left">
                                    <span class="font-bold">{{alertAccountsDict[editAlert.attributes.accountId].displayAccountId}}</span> Balance is Below <span class="font-bold">${{editAlert.attributes.value}}</span>
                                </div>     
                                <!--Balance Scheduled-->                 
                                <div v-else-if="editAlert.attributes.type == 'BalanceScheduled'"
                                    class="flex-grow text-left">
                                    <span class="font-bold">{{alertAccountsDict[editAlert.attributes.accountId].displayAccountId}}</span> Balance Scheduled <span class="font-bold">{{editAlert.attributes.frequency}}</span>
                                </div>      
                                <!--BillPay Paied-->
                                <div v-else-if="editAlert.attributes.type == 'BillPayPaymentPaid'"
                                    class="flex-grow text-left">
                                    <span class="font-bold">Billpay Payment Paigd</span>
                                </div>                                                                      
                                <!--Check Cleared-->
                                <div v-else-if="editAlert.attributes.type == 'CheckNumberWithinRangeHasCleared'"
                                    class="flex-grow text-left">
                                    <span class="font-bold">Check Cleared</span>
                                </div>                      
                                <!--Payment Due-->                       
                                <div v-else-if="editAlert.attributes.type == 'PaymentDueInXDays'"
                                    class="flex-grow text-left">
                                    <span class="font-bold">{{alertAccountsDict[editAlert.attributes.accountId].displayAccountId}}</span> Payment Due in <span class="font-bold">{{editAlert.attributes.value}} Days</span>
                                </div>
                                <div v-else
                                    class="flex-grow text-left">
                                    [{{editAlert.attributes.type}}][{{editAlert.attributes.accountId}}][{{editAlert.attributes.value}}]
                                </div>

                            </div>
                        </div>
                        <!--New/Edit Alert Form-->
                        <div v-if="alertMode == 'new' || alertMode == 'edit'" class="w-full flex flex-col">
                            <Field label="Alert Type"
                                type="select"
                                :formValue="fv.alertType"
                                :options="alertTypeSelectOptions" />
                            <Field label="Delivery Method"
                                v-if="fv.alertType.value != ''"
                                type="select"
                                :formValue="fv.alertDelivery"
                                :options="[
                                    { value: 'All', label: 'Email + SMS' },
                                    { value: 'Email', label: 'Email' },
                                    { value: 'SMS', label: 'SMS' }
                                ]" />                           
                            <Field v-if="alertTypeDict[fv.alertType.value] != undefined 
                                && alertTypeDict[fv.alertType.value].account" label="Account"
                                type="select"
                                :formValue="fv.alertAccount"
                                :options="alertAccountSelectOptions" />
                            <Field v-if="alertTypeDict[fv.alertType.value] != undefined 
                                && alertTypeDict[fv.alertType.value].amount" 
                                class="w-full" label="Amount"
                                type="number"
                                :formValue="fv.alertAmount" />
                            <Field v-if="alertTypeDict[fv.alertType.value] != undefined 
                                && alertTypeDict[fv.alertType.value].days" 
                                class="w-full" label="Days Notice"
                                type="number"
                                :formValue="fv.alertDays" />
                        </div>
                    </Form>
                </div>              
                <Form v-else-if="focusedCategory == 'sms'" class="w-full flex-col flex" submitText="Update SMS Settings">
                <!--SMS Alerts-->  
                    <Field label="SMS Alerts Enabled"
                        type="select"
                        :options="[{}]" />
                    <div class="w-full flex flex-row items-center justify-between">
                    <Field class="w-1/2" label="Mobile Number"
                        type="number"
                        :options="[{}]" />
                    <Field class="w-1/2" label="Carrier"
                        type="select"
                        :options="[{label: 'AT&T', value: 'att'}]" />
                    </div>
                </Form>           
                <Form v-else-if="focusedCategory == 'address'" 
                    class="w-full flex-col flex" 
                    :submitText="`Update ${editingAddressNumber == 1 ? 'Primary' : 'Alternate'} Address`" 
                    :showSubmit="address1.attributes && editingAddressNumber != ''"
                    :showCancel="address1.attributes && editingAddressNumber != ''"
                    @submit="submitUpdateAddress"
                    @cancel="editingAddressNumber = ''">
                    <div v-if="!address1.attributes"
                        class="mn3-content p-2 rounded-sm">
                        Loading Address Information
                    </div>
                    <div class="w-full flex flex-col" v-else>
                    <div class="w-full flex justify-between items-center p-2">
                        <select ref="addressSelect" 
                            @change="submitUpdateUsedAddress"
                            v-model="usingAddressNumber"
                            class="p-2 border rounded-sm w-1/2"><option value="1">Using Primary Address</option><option value="2">Using Alternate Address</option></select>
                        <div class="flex flex-col mx-auto" v-if="usingAddress.attributes">
                            <div>{{usingAddress.attributes.line1}}</div>
                            <div v-if="usingAddress.attributes.line2">{{usingAddress.attributes.line2}}</div>
                            <div>{{usingAddress.attributes.city}} {{usingAddress.attributes.state}} {{usingAddress.attributes.zip}}</div>
                        </div>
                    </div>
                    <div v-if="editingAddressNumber == ''" class="p-2">
                        <Button icon="home" label="Edit Saved Addresses" @click="startEditAddress(usingAddressNumber)"/>
                    </div>
                    <div v-else class="w-full flex flex-col">
                        <div class="font-bold text-center text-lg mt-5 mn3-content-alt py-1 uppercase">Editing {{editingAddressNumber == 1 ? 'Primary' : 'Alternate'}} Address</div>
                        <div class="w-full flex justify-between items-center p-2">
                            <Button @click="startEditAddress(1)" :class="`${editingAddressNumber == 1 ? 'opacity-100' : 'opacity-50'} flex-grow`" label="Edit Primary Address" />
                            <Button @click="startEditAddress(2)" :class="`${editingAddressNumber == 2 ? 'opacity-100' : 'opacity-50'} flex-grow`" label="Edit Alternate Address" />
                        </div>
                        <!--Address Change-->     
                        <Field label="Address"
                            :formValue="fv.editAddressLine1"
                            type="text" />
                        <Field label="Address Line 2 (Optional)"
                            :formValue="fv.editAddressLine2"
                            type="text" />
                        <Field label="City"
                            :formValue="fv.editAddressCity"
                            type="text" />
                        <Field label="State"
                            :formValue="fv.editAddressState"
                            maxLength="2"
                            type="text" />                        
                        <Field label="Zip"
                            :formValue="fv.editAddressZip"
                            type="number" />
                    </div>
                </div>
                </Form>                
                <Form v-else-if="focusedCategory == 'cards'" class="w-full flex-col flex"
                    :showSubmit="false"
                    :showCancel="false">             
                    <div v-for="card in cardsSorted" :key="card.attributes.displayId"
                        :class="`${confirmCard == card.attributes.displayId && card.attributes.status == 'Active' ? 'mn3-debit' 
                            : confirmCard == card.attributes.displayId && card.attributes.status != 'Active' ? 'mn3-credit' 
                            : card.attributes.status == 'Active' ? '' : 'mn3-debit'} 
                            ${confirmCard != '' && confirmCard != card.attributes.displayId ? 'opacity-30' : ''} mn3-content flex flex-col items-center p-2`">
                        <div v-if="confirmCard == card.attributes.displayId"
                            :class="`${card.attributes.status == 'Active' ? 'mn3-cancel' : 'mn3-submit'} w-full text-center font-bold rounded-sm`">
                            Are you sure you want to {{card.attributes.status == 'Active' ? 'disable' : 'enable'}} this card?
                        </div>
                        <div class="w-full flex flex-row items-center p-2">
                            <Icon :id="card.attributes.status == 'Active' ? 'credit-card' : 'x'" class="h-10 w-10 mr-2 flex-shrink-0" />
                            <div class="flex flex-col leading-4 flex-grow">
                                <div class="font-bold "><span class="ml-1 text-sm italic" v-if="card.attributes.status == 'displayId'">Disabled</span></div>
                                <div :class="`${card.attributes.status == 'Suspended' && confirmCard != card.attributes.displayid ? 'line-through' : ''} font-mono`">{{card.attributes.displayId}}</div>
                            </div>
                            <Button v-if="confirmCard != card.attributes.displayId" @click="confirmCard = card.attributes.displayId" 
                                :type="card.attributes.status == 'Active' ? 'button' : 'cancel'"
                                padding="1"
                                text="base"
                                :icon="card.attributes.status == 'Active' ? 'lock-closed' : 'lock-open'"
                                :label="card.attributes.status == 'Active' ? 'Disable Card' : 'Enable Card'" class="w-40" />
                        </div>
                        <div v-if="confirmCard == card.attributes.displayId"
                            class="flex flex-row w-full gap-x-1">
                            <Button :label="`Yes, ${card.attributes.status == 'Active' ? 'Disable' : 'Enable'} this Card`" 
                                :type="card.attributes.status == 'Active' ? 'cancel' : 'submit'"
                                :icon="card.attributes.status == 'Active' ? 'lock-closed' : 'lock-open'"
                                class="w-2/3"
                                @click="cardToggle(card)" />
                            <Button @click="confirmCard = ''" label="Cancel" class="w-1/3" />
                        </div>
                    </div>
                </Form>
                </transition>
            </div>
            
        </div>
    </div>
</template>

<script>
    import { useStore } from 'vuex'
    import { ref } from 'vue'

    import Button from '@/components/Button.vue'
    import Form from '@/components/Form.vue'
    import Field from '@/components/Field.vue'
    import Icon from '@/components/Icon.vue'
    import PasswordChecker from '@/components/PasswordChecker.vue'

    export default {
      name: 'User Settings',
      components: {
          Button,
          Form,
          Field,
          Icon,
          PasswordChecker
      },
      created(){
            document.title="Settings";
            this.buildOptions();
            this.loadMobileOptions();
            if(this.$route.params.sub_menu != undefined){
                this.focusCategory(this.$route.params.sub_menu)
            }
            this.loadPasswordReqs();
      },
      computed:{        
            isJointAccount(){            
                let mship = this.$store.state.memberships.find((el) => el.id.split('/').pop() == this.$store.state.membershipId);
                return mship ? mship.attributes.joint : false;
            },
            /* Options for alert account dropdown */ 
            alertAccountSelectOptions(){

                if(this.alertOptions == undefined){
                    return []
                }

                let options = [];
                for(var ii = 0; ii < this.alertOptions.accounts.length; ii++){
                    options.push({value: this.alertOptions.accounts[ii].id,
                        label: this.alertOptions.accounts[ii].displayAccountId })
                }
                return options;
            },                       
            /* Options for alert type dropdown */ 
            alertTypeSelectOptions(){

                if(this.alertOptions == undefined){
                    return []
                }

                let options = [
                    { label: 'Select Alert Type', value: '' }
                ];
                for(var ii = 0; ii < this.alertOptions.types.length; ii++){
                    options.push({value: this.alertOptions.types[ii].type,
                        label: this.alertOptions.types[ii].title })
                }
                return options;
            },
            /* Sort cards so disabled cards are at end of array */
            cardsSorted(){
                return this.cards.sort(function(a,b){
                    if(a.status == 'Active' && b.status != 'Active') return -1;
                    else if(b.status == 'Active' && a.status != 'Active') return 1;
                    return 0;
                });
            },
            /* Dropdown options for mobile carriers */
            mobileCarrierOptions(){

                let options = [];

                for(var ii = 0; ii < this.fetchedMobileOptions.length; ii++){

                    options.push({
                        value: this.fetchedMobileOptions[ii].value,
                        label: this.fetchedMobileOptions[ii].title})

                }

                return options;
            },
            /* Dropdown options for the from account */
            optionsFrom(){                
                let options = [];
                for(var ii = 0; ii < this.fromAccountIds.length; ii++){
                    let key = this.fromAccountIds[ii]
                    let acct = this.accounts[key]     
                    let group = acct.ownerName

                    options.push({
                        label: `${acct.type}: ${acct.displayAccountId} • ${(acct.nickname != undefined) ? acct.nickname : acct.description} • Avail Bal: ${this.formatMoney(acct.availableBalance)}`,
                        value: key,
                        group: group
                    })
                }	        
                return options;
            },
            /* Dropdown options for the to account */
            optionsTo(){
                let options = [];
                let toMap = this.toAccountIdMap[this.fv['tranFrom'].value]        
                if(toMap !== undefined){        
                    for(var ii = 0; ii < toMap.length; ii++){        
                        let key = toMap[ii]
                        let acct = this.accounts[key]    
                        let group = acct.ownerName
                        let desc = 'Avail Bal';
                        let amt = acct.availableBalance;
                        let label = `${acct.type}: ${acct.displayAccountId} • ${(acct.nickname != undefined) ? acct.nickname : acct.description} • ${desc}: ${this.formatMoney(amt)}`;

                        
                        if(acct.type.toLowerCase() == 'loan'){
                            amt = acct.nextPaymentAmount
                            desc = 'Next Payment'
                        }

                        options.push({
                            label: label,
                            value: key,
                            group: group
                        });
                    }
                }else{
                    options.push({
                        label: 'From Account must be selected first',
                        value: -1
                    })
                }  

                return options;
            },
        /* Text for the Alert submit button */
        submitAlertText(){
            switch(this.alertMode){
                case 'delete':
                    return 'Delete Alert';

                case 'edit':
                    return 'Update Alert';

                default: 
                    return 'Add New Alert';
            }
        },
        /* Text for the Transfer submit button */
        submitTransferText(){
            switch(this.transferMode){
                case 'delete':
                    return 'Delete Transfer';

                case 'edit':
                    return 'Update Transfer';

                default: 
                    return 'Add Transfer';
            }
        },
        usingAddress(){
            if(this.usingAddressNumber == 2)
                return this.address2;

            return this.address1;

        }
      },
      methods:{
        
        /* Add a new account alert */
        async addAccountAlert(){
            //Check that all values are set
            let issueFound = false
            this.error = '';

            //Validate delivery method
            if(this.fv.alertDelivery.value == ''){
                issueFound = true
                this.error = 'Delivery method must be selected'
            }
            //Validate account if required
            else if(this.alertTypeDict[this.fv.alertType.value].account 
                && this.fv.alertAccount.value == ''){
                issueFound = true
                this.error = 'Account must be selected'
            }            
            //Validate amount if required
            else if(this.alertTypeDict[this.fv.alertType.value].amount
                && parseFloat(this.fv.alertAmount.value) <= 0){
                issueFound = true
                this.error = 'Amount must be entered'
            }            
            //Validate days if required
            else if(this.alertTypeDict[this.fv.alertType.value].days
                && parseInt(this.fv.alertDays.value) <= 0){
                issueFound = true
                this.error = 'Days must be entered'
            }


            if(!issueFound){

                let attributes = {
                    type: this.fv.alertType.value,
                    deliveryChannel: this.fv.alertDelivery.value
                }
                if(this.alertTypeDict[this.fv.alertType.value].account)
                    attributes['accountId'] = this.fv.alertAccount.value+""
                    
                if(this.alertTypeDict[this.fv.alertType.value].amount)
                    attributes['value'] = this.fv.alertAmount.value+""
                else if(this.alertTypeDict[this.fv.alertType.value].days)
                    attributes['value'] = this.fv.alertDays.value+""

                if(this.fv.alertType.value == 'BalanceScheduled'){
                    attributes['frequency'] = "Daily"
                }

                let data = {
                    data: {
                        attributes: attributes
                    }
                }                

                try{
                    await this.$mapi.post('alerts', data)
                    
                    this.note = 'Account Alert Added'
                    this.alertMode = 'view';
                    this.loadAlerts()
                }
                catch(ex){
                    //set generic error
                    let errorDetail =  ex.response.data.errors[0].detail;
                    if(errorDetail.indexOf(':') != -1)
                        this.error = errorDetail.split(':')[1];
                    else
                        this.error = errorDetail
                }
            }
        },
        /* Add a new recurring transfer */
        async addRecurringTransfer(){
            //Check that all values are set
            let issueFound = false

            this.error = '';
            if(parseFloat(this.fv.tranAmount.value) <= 0){
                issueFound = true
                this.error = 'Amount must be entered'
            }
            else if(this.fv.tranFrequency.value == ''){
                issueFound = true
                this.error = 'Frequency must be selected'
            }
            else if(this.fv.tranFrom.value == ''){
                issueFound = true
                this.error = 'Account From must be selected'
            }
            else if(this.fv.tranTo.value == ''){
                issueFound = true
                this.error = 'Account To must be selected'
            }
            else if(this.fv.tranOn.value == ''){
                issueFound = true
                this.error = 'First Transfer Date must be entered'
            }

            //Check valid date
            let dateSplit = this.fv.tranOn.value.split('-')
            if(dateSplit.length < 3){
                issueFound = true
                this.error = 'Invalid Transfer Date entered'
            }else if(dateSplit[1].length > 2 ||dateSplit[2].length > 2 ||dateSplit[0].length > 4){
                issueFound = true
                this.error = 'Invalid Transfer Date entered'
            }
            else{
                //Have to replace - with / because js
                let toDate = new Date(this.fv.tranOn.value.replace('-', '/').replace('-', '/'));
                let today = new Date( new Date().toDateString())
                if(!(toDate >= today)){
                    issueFound = true
                    this.error = 'Transfer Date cannot be in the past'
                }
            }

            if(!issueFound){
                let data = {
                    data: {
                        attributes: {
                            amount: this.fv.tranAmount.value,
                            frequency: this.fv.tranFrequency.value,
                            fromAccountId: this.fv.tranFrom.value,
                            startDate: this.fv.tranOn.value,
                            toAccountId: this.fv.tranTo.value
                        },
                        type: 'RecurringTransfer'
                    }
                }                

                try{
                    await this.$mapi.post('recurring', data)
                    
                    this.note = 'Recurring Transfer Added'
                    this.transferMode = 'view';
                    this.loadRecurringTransfers()
                }
                catch(ex){
                    //set generic error
                    this.error = ex;
                }
            }
        },
        /* Convert fetched alert accts from array into a dictionary */
        alertAccountsToDict(){   
            this.alertAccountsDict = {}
            if(this.alertOptions == undefined || this.alertOptions.types == undefined){
                return []
            }
            for(var ii = 0; ii < this.alertOptions.accounts.length; ii++){
                this.alertAccountsDict[this.alertOptions.accounts[ii].id] = this.alertOptions.accounts[ii]
            }
        }, 
        /* Convert fetched alert types from options into a dictionary */       
        alertTypesToDict(){   
            this.alertTypeDict = {}
            if(this.alertOptions == undefined || this.alertOptions.types == undefined){
                return []
            }
            for(var ii = 0; ii < this.alertOptions.types.length; ii++){
                this.alertTypeDict[this.alertOptions.types[ii].type] = this.alertOptions.types[ii]
            }
        },
        /* Build list of main setting sub-menu options */
        buildOptions(){
            
            if(!this.isJointAccount)
            this.categories['mobile'] = {
                    id: 'mobile',
                    label: 'Mobile Info',
                    icon: 'mobile'
                }

            // Addresses book for sending funds
            if(this.$store.state.fi.id == 2)
                this.categories['addresses'] = {
                    id: 'addresses',
                    label: 'Address Book',
                    icon: 'book-open'
                }
            
            // Change primary/secondary address
            if(!this.isJointAccount && 
                this.$store.state.fi.addressChangeEnabled){
                this.categories['address'] = {
                        id: 'address',
                        label: 'Change Address',
                        icon: 'home'
                    }
            }
            
            this.categories['login'] = {
                    id: 'login',
                    label: 'Login Settings',
                    icon: 'key'
                }

            if(this.$store.state.fi.recurringEnabled)
                this.categories['transfers'] = {
                        id: 'transfers',
                        label: 'Recurring Transfers',
                        icon: 'calendar-dates'
                    }
            
            if(!this.isJointAccount && this.$store.state.fi.alertsEnabled)
                this.categories['alerts'] = {
                        id: 'alerts',
                        label: 'Account Alerts',
                        icon: 'exclamation'
                    }

            if(!this.isJointAccount && this.$store.state.fi.cardStatusEnabled){
                this.categories['cards'] = {
                    id: 'cards',
                    label: 'Card Control',
                    icon: 'credit-card'
                }
            }


                /*
            this.categories['sms'] = {
                    id: 'sms',
                    label: 'SMS Alerts',
                    icon: 'mobile'
                }
            this.categories['address'] = {
                    id: 'address',
                    label: 'Address Change',
                    icon: 'home'
                }*/
        },
        async cardToggle(card){
            let data = {
                data: {
                    attributes: {
                        status: card.attributes.status == 'Active' ? 'Suspended' : 'Active'
                    }
                }
            }
            await this.$mapi.patch(card.links.self, data);
            this.confirmCard = '';
            this.note = card.attributes.status == 'Active' ? 'Card Disabled' : 'Card Enabled. It may take 15 minutes for this card to become usable.';
            await this.loadCards();
        },
        async focusCategory(key){
            this.focusedCategory = key
            this.error = ''
            this.note = ''
            this.disableSubmit = false
            
            switch(key){
                case 'address':
                    await this.loadAddressInfo()
                break;

                case 'alerts':
                    await this.loadAlertOptions()
                    this.alertTypesToDict()
                    this.alertAccountsToDict()
                    this.loadAlerts()
                    break;

                case 'cards':
                    await this.loadCards();
                    break;
                
                case 'mobile':
                    //Load current member info
                    let fetched = await this.$mapi.get('mobile');
                    if(fetched.data.data.attributes.mobileNumber != undefined && fetched.data.data.attributes.mobileNumber.length == 10){
                        let n = fetched.data.data.attributes.mobileNumber;
                        let foramttedNumber = n.substring(0,3) + '-' + n.substring(3,6)+'-'+n.substring(6)
                        this.fv.mobileNumber.value = foramttedNumber                        
                    }                    
                    this.fv.mobileCarrier.value = fetched.data.data.attributes.mobileGateway
                    this.showMobileConfirm = false
                    break;

                case 'login':
                    this.loadPasswordReqs()
                    break;

                case 'transfers':
                    this.loadTransAccounts()
                    this.loadRecurringTransfers()
                break;
                

            }
        },
        /* Format a value for display */
        formatMoney(val){
            if(isNaN(parseFloat(val)))
                return '';
            return this.mon.format(val);
        },
        async loadAddressInfo(){    
            this.address1 = {}
            this.address2 = {}
            try{
                const r = await this.$mapi.get('addresses');   
                this.address1 = r.data.data[0]
                this.address2 = r.data.data[1]    

                if(this.address2.attributes.type == 'Mailing')
                    this.usingAddressNumber = 2
                else
                    this.usingAddressNumber = 1

            }catch(ex){ 
                console.log('loadAddressInfo.catch: ', ex)
            }
        },
        async loadTransAccounts(){
            this.accounts = []
            this.fromAccountIds = []
            this.toAccountIdMap = []        
            // Load transfer options
            if(this.$store.state.fi.recurringEnabled){
                try{
                    const r = await this.$mapi.get('transfer-options?recurring');
                    let atr = r.data.data.attributes
                    
                    this.accounts = atr.accounts
                    this.fromAccountIds = atr.fromAccountIds
                    this.toAccountIdMap = atr.toAccountIdMap
                }catch(ex){ 
                    //Force inactive logout
                    this.$store.commit('setShowInactiveNotice', true);           
                    this.$authapi.logout();
                }
            }
        },
        async loadAlertOptions(){
            this.accounts = []
            this.fromAccountIds = []
            this.toAccountIdMap = []        
            // Load transfer options
            if(this.$store.state.fi.alertsEnabled){
                try{
                    const r = await this.$mapi.get('alert-options');                    
                    this.alertOptions = r.data
                }catch(ex){ 
                    //Force inactive logout
                    console.log('loadAlertOptions.catch: ', ex)
                    /*
                    this.$store.commit('setShowInactiveNotice', true);           
                    this.$authapi.logout();
                    */
                }
            }
        },
        /* Load current account alerts */
        async loadAlerts(){
            this.alerts = {}
            let fetched = await this.$mapi.get("alerts");  
            this.alerts = fetched.data.data
        },
        async loadCards(){
            this.cards = [];
            let fetched = await this.$mapi.get("cards");
            this.cards = fetched.data.data;
        },
        /* Load mobile options */
        async loadMobileOptions(){
            this.alerts = {}
            let fetched = await this.$mapi.get("mobile-options"); 
            this.fetchedMobileOptions = fetched.data.gateways
        },
        async loadPasswordReqs(){
            let fetched = await this.$uapi.get("update-password");   
            this.passwordReqs = fetched.data.data.attributes
        },
        /* Load current recurring transfers */
        async loadRecurringTransfers(){
            let fetched = await this.$mapi.get("recurring");  
            this.recurring = fetched.data.data
        },
        /* Open external settings in a new window */
        openSettings(){
            window.open(this.$store.state.fi.settingsUrl);
        },
        showNewTransfer(){
            this.transferMode = 'new'
            this.fv.tranAmount.value = 0
            this.fv.tranFrequency.value = ''
            this.fv.tranOn.value = ''
            this.fv.tranFrom.value = ''
            this.fv.tranTo.value = ''
        },
        startEditAddress(addressNumber){
            let address = addressNumber == 2 ? this.address2 : this.address1;

            this.fv['editAddressLine1'].value = address.attributes.line1 + ''
            this.fv['editAddressLine2'].value = address.attributes.line2  ? address.attributes.line2 + '' : ''
            this.fv['editAddressCity'].value = address.attributes.city + ''
            this.fv['editAddressState'].value = address.attributes.state + ''
            this.fv['editAddressZip'].value = address.attributes.zip + ''
            this.editingAddressNumber = addressNumber
        },
        submitAddAddress(){
            this.addresses.push({
                name: this.fv.alertType,
                rtn: this.fv.alertDays,
                account: this.fv.alertAccount,
            })
        },
        async submitMobileInfo(){

            if(this.disableSubmit){
                console.log('submit disabled')
                return;
            }
            this.disableSubmit = true;

            //If confirming mobile #
            if(this.showMobileConfirm){

                this.error = '';
                let issueFound = false;

                if(this.fv.mobileConfirm.value.length != 6){
                    issueFound = true;
                    this.error = '6 digit confirmation code must be entered';
                }

                //No issues found, post confirmation code
                if(!issueFound){
                    let data = {
                        data: {
                            attributes:{
                                code: this.fv.mobileConfirm.value,
                            }
                        }
                    }
                    try{
                        let r = await this.$mapi.post("mobile", data) 
                        console.log('_r: ', r)

                        //Success
                        if(r.data.errors == undefined){
                            this.focusCategory('mobile')
                            this.note = 'Mobile number updated.'
                            this.error = '';
                            this.showMobileConfirm = false;
                        }
                        //Invalid code
                        else{
                            this.error = 'Invalid code entered'
                        }
                        this.disableSubmit = false;
                    }catch(resp){
                        console.log('resp: ', resp)
                        let er = resp.response.data.errors[0].detail;
                        if(er == undefined)
                            er = resp.response.data.errors[0].title                        
                        this.error =  er;
                        this.disableSubmit = false;
                    }
                }else{
                        this.disableSubmit = false;
                }

            }
            //Initial post updating mobile #, will send confirmation code
            else{
                this.error = '';
                let issueFound = false;

                let numberBasic = parseInt(this.fv.mobileNumber.value.replace('-', '').replace('-', '')).toString().substring(0,10);
                //Check for valid phone / carrier
                if(this.fv.mobileCarrier.value == ''){
                    issueFound = true;
                    this.error = 'Mobile Carrier must be selected';
                }
                //Check for valid number
                else if(numberBasic.length < 10 || isNaN(numberBasic)){
                    issueFound = true;
                    this.error = 'Invalid number entered';
                }

                //No issues, post
                if(!issueFound){
                    let data = {
                        data: {
                            attributes:{
                                mobileNumber: numberBasic,
                                mobileGateway: this.fv.mobileCarrier.value
                            }
                        }
                    }
                    try{
                        await this.$mapi.post("mobile", data) 
                        this.note = 'A confirmation code has been sent to ' + this.fv.mobileNumber.value + ', enter the code below to update your account.'
                        this.error = '';
                        this.fv.mobileConfirm.value = '';
                        this.showMobileConfirm = true;
                        this.disableSubmit = false;
                    }catch(resp){
                        let er = resp.response.data.errors[0].detail;
                        if(er == undefined)
                            er = resp.response.data.errors[0].title                        
                        this.error =  er;
                        this.disableSubmit = false;
                    }
                }else{
                        this.disableSubmit = false;
                }

            }
        },
        /* Post a password update if valid */
        async submitUpdatePassword(){
            this.lastSub = 'password';
            this.note = '';
            if(this.passwordChecker.isValidPass()){
                let data = {
                    data: {
                        attributes:{
                            oldPassword: this.fv.oldPassword.value,
                            newPassword: this.fv.newPassword.value
                        }
                    }
                }
                try{
                    await this.$uapi.post("update-password", data) 
                    this.note = 'Password updated.'
                    this.error = '';
                }catch(resp){
                    let er = resp.response.data.errors[0].detail;
                    if(er == undefined)
                        er = resp.response.data.errors[0].title                        
                    this.error =  er;
                }
            }else{
                this.error = 'Valid new password is required'
            }
        },  
        async submitUpdateUsedAddress(evt){
            let url = evt.target.value == 2 ? this.address2.links.self : this.address1.links.self
            await this.$mapi.patch(url, {
                "data": {
                    "attributes": {
                    "type": "Mailing"
                    }
                }
            }); 
            this.note = 'Active address updated'    
         },
        async submitUpdateAddress(){
            console.log('submitUpdateAddress: ', this.editingAddressNumber, this.fv)  
            this.error = '';
            if(this.fv.editAddressLine1.value.length == 0){
                this.error = 'Address Line1 Required';
            }
            else if(this.fv.editAddressCity.value.length == 0){
                this.error = 'City Required';
            }
            else if(this.fv.editAddressState.value.length == 0){
                this.error = 'State Required';
            }
            else if(this.fv.editAddressZip.value.length < 5){
                this.error = '5 Digit Zip Required';
            }
            else{
                let url = this.editingAddressNumber == 2 ? this.address2.links.self : this.address1.links.self
                let data = {
                    "data": {
                        "attributes": {
                        "line1": this.fv.editAddressLine1.value + '',
                        "line2": this.fv.editAddressLine2.value + '',
                        "city": this.fv.editAddressCity.value + '',
                        "state": this.fv.editAddressState.value + '',
                        "zip": this.fv.editAddressZip.value + ''
                        }
                    }
                };
                
                try{
                    let resp = await this.$mapi.put(url, data);
                    console.log('resp: ', resp);   
                    this.loadAddressInfo();
                    this.note = 'Address saved'; 
                }catch(resp){
                    console.log('catch resp: ', resp);
                    this.alert = 'Address not saved';
                }
            }

        },
        /* Post a username update if valid */
        async submitUpdateUsername(){
            this.error = '';
            this.note = '';
            this.lastSub = 'username';
            if(this.fv['newUsername'].value.length < 6 || this.fv['newUsername'].value.length > 20){
                this.error="Username must be 6 to 20 characters long"     
            } else {

                let data = {
                    "data": {
                        "attributes": {
                            "username": this.fv['newUsername'].value
                        }
                    }
                }
                try{
                    let resp = await this.$uapi.patch("", data);
                    this.note = 'Your username has been updated';                    
                }catch(resp){
                    let errorDetail = resp.response.data.errors[0].detail;
                    let errorTitle = resp.response.data.errors[0].title;
                    if(errorDetail != undefined){
                        this.error = 'Error: ' + errorDetail;
                    }else if(errorTitle != undefined){
                        this.error = 'Error: ' + errorTitle;
                    }else{
                        this.error = 'Error: There was an issue updating your username.';
                    }
                }


            }
        },
        /* Format transfer on for display (ex: mon->Monday, 3->3rd, etc) */
        toFullTransferOn(transferOn){
            //Check if number or day of week
            if(isNaN(transferOn)){
                return this.toFullDay(transferOn);
            }else{
                return this.toDisplayDate(transferOn);
            }
        },
        toFullFrequency(transferFrequency){            
            let ret = '';
            switch(transferFrequency){
                case 'weekly':
                    ret = 'Weekly'
                    break;
                    
                case 'biweekly':
                    ret = 'Bi-Weekly'
                    break;
                    
                case 'monthly':
                    ret = 'Monthly'
                    break;
                    
                case 'bimonthly':
                    ret = 'Bi-Monthly'    
                    break;    

                case 'quarterly':
                    ret = 'Quarterly'    
                    break;            
            }
            return ret;
        },
        /* Return full day from shorthand */
        toFullDay(transferOn){
            switch(transferOn){
                case 'mon':
                    return 'Monday';
                    
                case 'tue':
                    return 'Tuesday';
                    
                case 'wed':
                    return 'Wednesday';
                    
                case 'thu':
                    return 'Thursday';
                    
                case 'fri':
                    return 'Friday';
                    
                case 'sat':
                    return 'Saturday';
                    
                case 'sun':
                    return 'Sunday';
            }
            return 'ERR';
        },
        /* Return the day of the month for display */
        toDisplayDate(transferOn){
            switch(transferOn.slice(-1)){
                case '0':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    return 'the ' +transferOn + 'th';
                    
                case '1':
                    return 'the ' +transferOn + 'st';
                    
                case '2':
                    return 'the ' +transferOn + 'nd';
                    
                case '3':
                    return 'the ' +transferOn + 'rd';
            }

            return 'ERR';
        },
        /* An account alert form was submit */
        async submitAlert(){
            switch(this.alertMode){
                case 'new':
                    await this.addAccountAlert()
                    break;
                case 'edit':
                    console.log('Edit Alert');
                    break;
                case 'delete':                      
                    await this.$mapi.delete("alerts/" + this.editAlert.id.split('alerts/')[1])
                    this.note = 'Account Alert Deleted'
                    this.alertMode = 'view';
                    this.loadAlerts()
                    break;
            }
        },
        /* A recurring transfer form was submit */
        async submitTransfer(){
            switch(this.transferMode){
                case 'new':
                    await this.addRecurringTransfer()
                    break;
                case 'delete':
                    await this.$mapi.delete("recurring/" + this.editTransfer.id.split('recurring/')[1])
                    this.note = 'Recurring Transfer Deleted'
                    this.transferMode = 'view';
                    this.loadRecurringTransfers()
                    break;
            }
        }
      },  
      setup(){

        let store = useStore();
        const alertAccountsDict = ref({})
        const alertTypeDict = ref({})
        const externalSettings = ref(store.state.fi.settingsUrl != undefined)
        const fetchedMobileOptions = ref({})
        const focusedCategory = ref('login')
        const recurring = ref({})
        const alertMode = ref('view');
        const alertOptions = ref([])
        const alerts = ref({})
        const disableSubmit = ref(false)
        const editAlert = ref({})
        const error = ref('')
        let mon                                     
            = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
        const lastSub = ref('') //Last form submit, changes where alert shown
        const note = ref('')
        const passwordChecker = ref(null)
        const passwordReqs = ref([])
        const transferMode = ref('view')
        const editTransfer = ref({})
        const accounts = ref([])
        const fromAccountIds = ref([])
        const toAccountIdMap = ref({})                          
        const transfers = ref([
            { frequency: 'w', on: 'mon', from: 'SH-01', to: 'LN-02', amount: 500, next: '03/20/2023' },
            { frequency: 'bw', on: 'tue', from: 'SH-01', to: 'LN-02', amount: 4400, next: '03/28/2023' },
            { frequency: 'm', on: '5', from: 'SH-01', to: 'LN-02', amount: 1500, next: '04/05/2023' },
            { frequency: 'bm', on: '3', from: 'SH-01', to: 'LN-02', amount: 200, next: '05/03/2023' }
        ])
        const showMobileConfirm = ref(false)
        const usingAddressNumber = ref(1)
        const editingAddressNumber = ref('')
        const address1 = ref({})
        const address2 = ref({})
        const confirmCard = ref('')

        const fv = {
            alertType: ref(''),
            alertAmount: ref(0),
            alertAccount: ref(''),
            alertDays: ref(1),
            alertDelivery: ref('All'),
            editAddressLine1: ref(''),
            editAddressLine2: ref(''),
            editAddressCity: ref(''),
            editAddressState: ref(''),
            editAddressZip: ref(''),
            oldPassword: ref(''),
            mobileCarrier: ref(''),
            mobileConfirm: ref(''),
            mobileNumber: ref(''),
            newPassword: ref(''),
            newPassword2: ref(''),
            newUsername: ref(''),
            passCurrent: ref(''),
            tranAmount: ref(0),
            tranFrequency: ref(''),
            tranFrom: ref(''),
            tranTo: ref(''),
            tranOn: ref('')
        }
        const categories = ref({});
        const addresses = ref([
        { name: 'Person McPerson', rtn: '135879614', account: '24' },
        { name: 'George Georgie', rtn: '00001234567', account: '0015487' },
        { name: 'Person McPerson SH', rtn: '135879614', account: '124' }
        ])
        const cards = ref([])

        return{
            accounts,
            address1,
            address2,
            addresses,
            alertAccountsDict,
            alertMode,
            alertOptions,
            alertTypeDict,
            alerts,
            cards,
            categories,
            confirmCard,
            disableSubmit,
            editingAddressNumber,
            editAlert,
            editTransfer,
            error,
            externalSettings,
            fetchedMobileOptions,
            focusedCategory,
            fromAccountIds,
            fv,
            transferMode,
            lastSub,
            mon,
            note,
            passwordChecker,
            passwordReqs,
            recurring,
            showMobileConfirm,
            toAccountIdMap,
            transfers,
            usingAddressNumber
        }    
      }
    }
</script>
