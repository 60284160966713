<template>
    <div class="max-w-screen-lg w-full">
        <div class="w-full flex flex-row flex-wrap md:flex-nowrap items-center justify-center mb-4">
            <div v-for="cat in categories"
                :key="cat.id"
                @click="activeCategory = cat.id; loadRecords(cat.id)"
                class="w-1/2 md:w-1/4 my-2 md:my-1">
                <div :class="`${cat.id == activeCategory ? 'font-bold' : 'opacity-90'} border-black text-center mx-2 text-lg cursor-pointer leading-4 hover:opacity-100 uppercase flex flex-row items-center`">
                    <Icon :id="cat.icon" class="h-6 w-6 mr-1 flex-shrink-0" />
                    <div class="text-left">{{cat.title}}</div>
                </div>
            </div>
        </div>   
        <div class="w-full flex flex-row mn3-content-alt rounded-sm overflow-hidden mb-2 items-center">
            <div @click="loadFirst()" :class="`${!firstUrl ? 'opacity-20' : '' } py-3 duration-150 w-1/3 p-2 mn3-content-alt-hover text-center cursor-pointer flex flex-row items-center justify-start`"><Icon id="chev-left" class="h-6 w-6" />Current Year</div>
            <div class="w-1/3 p-2 text-center font-bold cursor-default tracking-tight uppercase text-sm leading-4"> Viewing {{viewingYear}}</div>
            <div @click="loadNext()" :class="`${!nextUrl ? 'opacity-20' : '' } py-3 duration-150  w-1/3 p-2 mn3-content-alt-hover cursor-pointer flex flex-row items-center justify-end`">Prev Year<Icon id="chev-right" class="h-6 w-6" /></div>
        </div>    
		<transition name="table" mode="out-in">
			<Table
                    :key="render" 
                    :useRecords="false"
                    :hasQuickFilter="true"
                    @quickFilter="(e) => { statementFilter = e; }">
                    <div class="w-full">                            
                <div v-if="statementsToShow.length == 0"
                    class="rounded-sm mn3-content p-2 text-center my-2 italic">
                            No Records to Display
                </div>
                <div class="w-full flex flex-col items-center mn3-content mn3-content-hover cursor-pointer " 
                    v-for="rec in statementsToShow"
                    :key="rec.id">
                    <div class="flex flex-row items-center justify-between w-full">
                        <div @click="clickStatement(rec.links.self)" class="p-2"><Icon id="receipt" class="h-6 w-6" /></div>
                        <div 
                            @click="clickStatement(rec.links.self)"
                            class="bold text-lg p-2">{{rec.attributes.title}}</div>
                        <div @click="clickStatement(rec.links.self)" class="flex-grow h-10 p-2" />
                        <Icon id="export" class="h-6 w-6 mx-2" title="Open In New Window"
                            @click="openStatement(rec.links.self)" />
                        <Icon v-if="focusedStatementSelf != rec.links.self && inlineEnabled" id="eye" class="h-6 w-6 mx-2" title="View Statement"
                            @click="focusStatement(rec.links.self)" />                        
                        <Icon v-else-if="inlineEnabled" id="x" class="h-6 w-6 mx-2" title="Close Statement"
                            @click="focusStatement(rec.links.self)" />
                    </div>
                    <iframe v-if="focusedStatementSelf == rec.links.self" 
                        id="frame" 
                        :key="render"
                        class="w-full md:rounded"
                        :src="focusedStatement" 
                        :style="frameHeight" />
                        
                </div>
                </div>

            </Table>
		</transition>
        
        <transition name="fade">
            <div v-if="showDownloadNotice"
                class="p-2 text-xs mn3-navbar-notice mt-2 flex flex-row items-center rounded-sm">
                <Icon id="exclamation" class="h-8 w-8 mr-2" />
                <div class="flex flex-col">
                    <div>Note: Depending on your browser's settings the PDF may open in a new window, new tab, or download to your computer.</div>
                    <div class="flex flex-row flex-wrap items-center">If after clicking on the view external button <div class="flex flex-row items-center mx-1">(<Icon id="export" class="inline h-4 w-4" />)</div> you do not see your report, check your browser's downloads.</div>
                </div>
            </div>
        </transition>

        <div v-if="!isJointAccount" class="w-full flex flex-col md:flex-row justify-between mn3-content pt-4">
            <div class="w-full max-w-screen-sm mx-auto mn3-content p-2 rounded flex flex-row justify-center items-center whitespace-nowrap mt-4">
                <div><Icon id="loan" class="h-16 w-16 mr-2" /></div>
                <div class="flex flex-col items-center">
                    <p class="text-center  mb-2 md:mb-0 mx-4 uppercase text-sm font-bold">E-Statements are {{!memberStatementsEnabled ? 'not' : ''}} enabled</p>
                    <div @click="enableStatements(!memberStatementsEnabled)" 
                        :class="`${memberStatementsEnabled ? 'mn3-cancel' : 'mn3-submit'} mn3-button w-56 p-2 rounded-sm cursor-pointer flex flex-row items-center justify-center`">
                        <p>{{memberStatementsEnabled ? 'Disable' : 'Enable'}} Statements</p>
                    </div>
                </div>
            </div>

            <div v-if="$store.state.fi.noticesEnabled" 
                class="w-full max-w-screen-sm mx-auto mn3-content p-2 rounded flex flex-row justify-center items-center whitespace-nowrap mt-4">
                <div><Icon id="inbox-stack" class="h-16 w-16 mr-2" /></div>
                <div class="flex flex-col items-center">
                    <p class="text-center  mb-2 md:mb-0 mx-4 uppercase text-sm font-bold">Notices are {{!memberNoticesEnabled ? 'not' : ''}} enabled</p>
                    <div @click="enableNotices(!memberNoticesEnabled)" 
                        :class="`${memberNoticesEnabled ? 'mn3-cancel' : 'mn3-submit'} mn3-button w-56 p-2 rounded-sm cursor-pointer flex flex-row items-center justify-center`">
                        <p>{{memberNoticesEnabled ? 'Disable' : 'Enable'}} Notices</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { ref, computed } from 'vue'

	import Table from "@/components/Table.vue";
    import Icon from '@/components/Icon.vue'

	export default {
		components:{
            Icon,
			Table
		},
        computed:{
            isJointAccount(){            
                let mship = this.$store.state.memberships.find((el) => el.id.split('/').pop() == this.$store.state.membershipId);
                return mship ? mship.attributes.joint : false;
            },
            categories(){
                let cats = [];

                cats.push({title: 'Statements', id: 'statements', icon: 'loan'})

                if(this.$store.state.fi.noticesEnabled && this.memberNoticesEnabled){
                    cats.push({title: 'Notices', id: 'notices', icon: 'exclamation'})
                    cats.push({title: 'Receipts', id: 'receipts', icon: 'receipt'})
                    cats.push({title: 'Tax Forms', id: 'taxforms', icon: 'inbox-stack'})
                }

                return cats;
            },
            /* Returns if inline viewing of PDFs is enabled, or just external viewing */
            inlineEnabled(){
                //Disabled if android
                return !(this.$store.state.isAndroid);
            },
            memberStatementsEnabled(){
                this.updateStatus //Included to trigget checking status when updateStatus is changed
                let membs = this.$store.state.memberships;
                let acct = {};
                for(var ii = 0; ii < membs.length; ii++){
                    if(membs[ii].attributes.id == this.$store.state.membershipId){
                        acct = membs[ii];
                    }
                }

                //No account found, return false
                if(acct.attributes == undefined){
                    return false
                }
                return acct.attributes.statementsEnabled;
            },
            memberNoticesEnabled(){
                this.updateStatus //Included to trigget checking status when updateStatus is changed
                let membs = this.$store.state.memberships;
                let acct = {};
                for(var ii = 0; ii < membs.length; ii++){
                    if(membs[ii].attributes.id == this.$store.state.membershipId){
                        acct = membs[ii];
                    }
                }

                //No account found, return false
                if(acct.attributes == undefined){
                    return false
                }
                return acct.attributes.noticesEnabled;
            },
            statementsToShow(){
                this.tableKey
                let all = this.records;
                if(this.statementFilter.length == 0)
                    return all;

                let show = [];
                let qf = this.statementFilter.toLowerCase();
                for(var ii = 0; ii < all.length; ii++){
                    if(all[ii].attributes.title.toLowerCase().indexOf(qf) != -1){
                        show.push(all[ii]);
                    }
                }      

                return show;
            }
        },
        methods: {
            /* If inline enabeld display inline, otherwise open in window */
            clickStatement(path){
                if(this.inlineEnabled)
                    this.focusStatement(path)
                else
                    this.openStatement(path)
            },
            enableNotices(enable){
                this.$mapi.patch("", 
                {
                    "data": {
                        "attributes": {
                            "noticesEnabled": enable
                        }
                    }
                });
                this.updateStatus++;   

                //refresh the screen
                this.$router.go();
            },
            enableStatements(enable){
                this.$mapi.patch("", 
                {
                    "data": {
                        "attributes": {
                            "statementsEnabled": enable
                        }
                    }
                });
                this.updateStatus++;   

                //refresh the screen
                this.$router.go();
            },
            async loadFirst(){
                this.viewingYear = 'This Year and Last Year';
                await this.$mapi.get(this.firstUrl).then(r=>{
                    this.formatFetched(r);
                });
            },
            async loadNext(){
                //Get the year for display
                let split = this.nextUrl.split('=');
                if(split.length < 2) return;
                this.viewingYear = split[1];

                await this.$mapi.get(this.nextUrl).then(r=>{
                    this.formatFetched(r);
                });
            },
            async loadRecords(category){
                this.viewingYear = 'This Year and Last Year';
                await this.$mapi.get(category).then(r=>{
                    this.formatFetched(r);
                });
            },
            formatFetched(r){
                this.records = r.data.data;      
                // Check nextURL
                if(r.data.links && r.data.links.next){             
                    this.nextUrl = r.data.links.next;
                }else{ this.nextUrl = undefined; }
                // Check firstUrl
                if(r.data.links && r.data.links.first){             
                    this.firstUrl = r.data.links.first;
                }else{ this.firstUrl = undefined; }
                this.tableKey += 1; //Re-render the table component
            },
            async focusStatement(path){
                if(!this.inlineEnabled){
                    return;
                }

                if(this.focusedStatementSelf == path){
                    this.focusedStatementSelf = '';
                    this.focusedStatement = '';
                }else{
                    let fetch = await this.$mapi.get(path + '/url');
                    console.log('fetchUrl: ', fetch.data.url);                     
                    await new Promise(resolve => setTimeout(resolve, 50));          
                    this.focusedStatementSelf = path;   
                    this.focusedStatement = fetch.data.url;       
                    this.render++;                    
                    await new Promise(resolve => setTimeout(resolve, 50));      
                    this.render++;            
                }   
            },
            async openStatement(path){                
                let fetch = await this.$mapi.get(path + '/url');
                console.log('fetchUrl: ', fetch.data.url);     
                await new Promise(resolve => setTimeout(resolve, 50));
                window.open(fetch.data.url, '_blank');
            },                         
            windowResize(e){
                this.frameHeightUpdate++;
            }
        },
		data(){
			return {
				statements: [],
				tableKey: 1
			};
		},
		async created(){
			      
            if(!this.$store.state.fi.statementsEnabled && !this.$store.state.fi.noticesEnabled){
                this.$router.push('/');
            }

	  		document.title="Statements";
            this.frameHeightUpdate++;
            window.addEventListener("resize", this.windowResize);
      
            //Load statements if enabled
            if(this.$store.state.fi.statementsEnabled){
                this.activeCategory = 'statements';
                await this.loadRecords('statements');
            }
            //Else load notices default
            else{
                await this.loadRecords('notices');
            }
  
  


		},
        setup( prop ){
            const updateStatus = ref(0)
            const records = ref([])
            const render = ref(0)
            const showDownloadNotice = ref(false)
            const statementFilter = ref('')
            const focusedStatement = ref('')
            const focusedStatementSelf = ref('')
            const frameHeightUpdate = ref(0)
            const statements = ref([])
            const nextUrl = ref('')
            const firstUrl = ref('')
            const viewingYear = ref('This Year and Last Year')
            const frameHeight = computed({          
                get: () => {
                    frameHeightUpdate.value++;      
                        
                    if(window.innerWidth >= 768)                 
                        return `height: ${window.innerHeight-225}px;`;      
                    else 
                        return `height: ${window.innerHeight-132}px;`;           
                }
            });

            return {
                firstUrl,
                focusedStatement,      
                focusedStatementSelf,  
                frameHeight,
                frameHeightUpdate,
                records,
                render,
                nextUrl,
                showDownloadNotice,
                statementFilter,
                statements,
                updateStatus,
                viewingYear
            }
        }
	}
	
</script>

<style scoped>

.table-enter-from{
    opacity: 0;
    transform: translateY(-25px);
}

.table-enter-active{
    transition: all 0.15s ease-out;
    z-index: -1;
}

.table-leave-to{
    opacity: 0;
    transform: translateY(-25px);
}

.table-leave-active{
    transition: all 0.15s ease-out;
    z-index: -1;
}
</style>